import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { navigate, withPrefix } from 'gatsby-link';
import moment from 'moment';
import { useQueryParam } from 'gatsby-query-params';

import { Button, LinearProgress, TextField } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Alert from '@mui/material/Alert';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import StaticDatePicker from '@mui/lab/StaticDatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { validateEmail } from '../../api/userAuthApi';

import { setCookie, getCookie, saveInquiry } from '../../api/dataApi';
import { saveUserEvent } from '../../api/userAuthApi';
import * as offerCalc from '../../api/offerCalc';

import * as webSocketActions from '../../redux/actions/webSocketActions';

import AddressSearch from '../AddressSearch';
import FeaturedOfferElectric from '../OfferGrid/FeaturedOfferElectric';
import NoDeregulatedElectric from '../ConnectElectric/NoDeregulatedElectric';

import '../Articles/index.css';
import './index.css';

const providerList = [
  '4CHANGE ENERGY',
  'ABACUS ENERGY RETAIL ELECTRICITY',
  'Abacus Energy Retail Electricity',
  'ACACIA ENERGY',
  'ACCEL ENERGY',
  'ACCENT ENERGY',
  'ACCENT ENERGY TEXAS LP ',
  'ALLIANCE POWER COMPANY LLC ',
  'ALMIKA POWER',
  'AMBIT',
  'AMBIT ENERGY',
  'AMBIT TEXAS LLC ',
  'AMERICAN POWERNET MANAGEMENT LP ',
  'Amigo Commercial',
  'AMIGO ENERGY',
  'AMMPER POWER LLC ',
  'Ampra Energy',
  'AP COMMERCIAL',
  'AP GAS & ELECTRIC (TX) LLC ',
  'APG&E',
  'APG&E ENERGY SOLUTIONS',
  'ARROW ENERGY TX LLC ',
  'ASSURANCE ENERGY',
  'Axon Power',
  'AXON POWER & GAS LLC ',
  'AXPO US LLC ',
  'Beyond Energy',
  'BKV Electric',
  'BKV Electricity',
  'BKV Energy',
  'BKV Retail Power',
  'BKV-BPP RETAIL LLC ',
  'BKV-BPP RETAIL TEXAS',
  'BP ENERGY COMPANY ',
  'BP Energy Holding Company LLC ',
  'Branch Energy',
  'BRANCH ENERGY (TEXAS) LLC ',
  'BRIGHTSTAR POWER ',
  'BROOKLET ENERGY DISTRIBUTION LLC ',
  'CALPINE ENERGY SOLUTIONS LLC ',
  'CAPITAL ENERGY PA LLC ',
  'CHAMPION ENERGY INDUSTRIAL SERVICES I',
  'CHAMPION ENERGY INDUSTRIAL SERVICES II',
  'CHAMPION ENERGY INDUSTRIAL SERVICES III',
  'CHAMPION ENERGY SERVICES LLC ',
  'CHARIOT ENERGY',
  'Circular Energy',
  'CIRRO ENERGY',
  'CleanSky Energy',
  'CLEARVIEW ENERGY',
  'CLEARVIEW POWER INC ',
  'CLYDE ENERGY',
  'COMPASSION ENERGY',
  'CONOCOPHILLIPS COMPANY ',
  'Constellation',
  'CONSTELLATION NEWENERGY INC ',
  'COST PLUS ENERGY',
  'Cost Plus Power',
  'CPL',
  'CPL BUSINESS',
  'CPL RETAIL ENERGY',
  'CPL RETAIL ENERGY LP ',
  'DAVID ENERGY SUPPLY (TEXAS) LLC ',
  'DECLARATION ENERGY LLC ',
  'DIAMOND ENERGY',
  'DIAMOND ENERGY TX LLC ',
  'DIRECT ENERGY',
  'DIRECT ENERGY BUSINESS LLC ',
  'DIRECT ENERGY BUSINESS SERVICES',
  'DIRECT ENERGY LP ',
  'DIRECT ENERGY MULTI-FAMILY',
  'Discount Power',
  'DYNOWATT',
  'DYNOWATT POWERED BY ACCENT ENERGY',
  'EAGLE ENERGY',
  'EDF ENERGY SERVICES LLC ',
  'ELIGO ENERGY TX LLC ',
  'ENEL TRADING NORTH AMERICA LLC ',
  'Energy Rewards',
  'Energy Texas',
  'Energy to Go',
  'ENGIE RESOURCES LLC ',
  'ENPOWERED',
  'ENPOWERED USA INC ',
  'EPIQ ENERGY',
  'Evolve',
  'Evolve Energy',
  'EXPERT ENERGY',
  'Express Energy',
  'FIRST CHOICE POWER',
  'FIRST CHOICE POWER LLC ',
  'Flagship Power',
  'Flagship Power',
  'FREEPOINT ENERGY SOLUTIONS LLC ',
  'FRONTIER UTILITIES',
  'FRONTIER UTILITIES LLC ',
  'FULCRUM RETAIL ENERGY LLC ',
  'GEXA CORP',
  'GEXA ENERGY',
  'GEXA ENERGY CORP',
  'GEXA ENERGY LP ',
  'GEXA ENERGY.COM',
  'GOOD CHARLIE & CO LLC ',
  'GOOD CHARLIE OPS LLC',
  'Green Mountain',
  'Green Mountain Energy',
  'GREEN MOUNTAIN ENERGY COMPANY ',
  'Hello Energy',
  'HERITAGE POWER LLC ',
  'HUDSON ENERGY SERVICES LLC ',
  'IGS ENERGY',
  'INSPIRE ENERGY HOLDINGS LLC ',
  'IRONHORSE POWER SERVICES LLC ',
  'JP ENERGY RESOURCES LLC ',
  'Juice',
  'Juice',
  'JUST ENERGY',
  'Just Energy Commercial',
  'JUST ENERGY RGV',
  'JUST ENERGY TEXAS LP ',
  'Lone Star Energy',
  'MARKET PLACE ENERGY LLC ',
  'METROPLEX ENERGY INC ',
  'MI TEXAS REP 1 LLC ',
  'MI TEXAS REP 2 LLC ',
  'MIDAMERICAN ENERGY SERVICES LLC ',
  'Milestone Energy',
  'Mio Electric',
  'Mothership',
  'MP2 ENERGY TEXAS LLC ',
  'NATIONAL GAS & ELECTRIC TEXAS LLC ',
  ' NEC Co-op Energy ',
  ' NEC RETAIL',
  'NEW LEAF ENERGY ',
  'New Power Texas',
  'NEWENERGY',
  'NORTH AMERICAN POWER AND GAS LLC ',
  'NOVA ELECTRIC CO',
  'NOW POWER',
  'NRG',
  'NRG Business',
  'NRG CONTROLLABLE LOAD SERVICES LLC ',
  ' NUECES ELECTRIC COOPERATIVE (DISTRIBUTION SERVICE PROVIDER)',
  'Octopus',
  'Octopus Energy',
  'Octopus Energy LLC ',
  'OhmConnect Energy',
  'OHMCONNECT TEXAS LLC ',
  'ONPOINT ENERGY TEXAS LLC ',
  'OUR ENERGY LLC ',
  'PAYLESS POWER',
  'PENNYWISE ',
  'PENNYWISE POWER ',
  'PENSTAR POWER',
  'PENSTAR POWER LLC',
  'PENSTAR POWER LLC 1',
  'PERIDOT POWER LLC ',
  'Peso Power',
  'Pogo Energy',
  'POGO ENERGY LLC ',
  'POWER HOUSE ENERGY',
  'PowerNext',
  'POWERPASS',
  'Prepay Power',
  'PRO POWER PROVIDERS LLC ',
  'PRONTO POWER ',
  'PULSE POWER LLC ',
  'QUEXT ENERGY LLC ',
  'Ranchero Power',
  'RELIANT',
  'RELIANT ENERGY',
  'RELIANT ENERGY BUSINESS SERVICES',
  'RELIANT ENERGY RETAIL SERVICES LLC ',
  'REVOLUTION ENERGY',
  'REVOLUTION ENERGY LLC ',
  'RHYTHM',
  'Rhythm Energy',
  'RHYTHM OPS LLC ',
  'Rio Power & Light',
  'RODEO ENERGY',
  'SFE ENERGY TEXAS INC ',
  'SG&E',
  'Shell Energy Solutions',
  'SMART PREPAID ELECTRIC',
  'SMARTENERGY HOLDINGS LLC ',
  'SMARTESTENERGY US LLC ',
  'SmarTricity',
  'SOL ENERGY',
  'SOUTHERN FEDERAL POWER LLC ',
  'SOUTHWEST POWER & LIGHT',
  'SPARK ENERGY LLC ',
  'STARTEX POWER',
  'STAT Energy LLC ',
  'STREAM ENERGY',
  'STREAM SPE LTD ',
  'SUMMER ENERGY LLC ',
  'Sunrise Power',
  'SUNRISE POWER & GAS TEXAS',
  'Tara Commercial',
  'TARA ENERGY',
  'TARA ENERGY LLC ',
  'TARA ENERGY RESOURCES',
  'TARA POWER',
  'TESLA',
  'TESLA ENERGY VENTURES LLC ',
  'Texans Energy',
  'TEXAS RETAIL ENERGY LLC ',
  'TEXPO ENERGY',
  'TEXPO POWER LP ',
  'Think Energy',
  'TITAN GAS AND POWER',
  'TITAN GAS LLC ',
  'TOMORROW ENERGY CORP ',
  'TotalEnergies Gas & Power North America Inc ',
  'TOWN SQUARE ENERGY LLC ',
  'TRIEAGLE ENERGY LP ',
  'TRIEAGLE ENERGY SERVICES',
  'Tristar Energy',
  'TRUE COMMODITIES LLC ',
  'TRUE POWER',
  'True Prepaid',
  'TXU ENERGY',
  'TXU ENERGY RETAIL COMPANY LLC ',
  'UNO ENERGY',
  'US ENERGY SAVINGS CORP',
  'US RETAILERS LLC ',
  'V247 POWER CORPORATION ',
  'VALUE BASED BRANDS LLC ',
  'Value Energy',
  'Value Power',
  'VARSITY ENERGY LLC ',
  'Veteran Energy',
  'Viridian Energy',
  'VITAL POWER',
  'Watt Bro',
  'World Power & Gas LP ',
  'WTU',
  'WTU BUSINESS',
  'WTU RETAIL ENERGY ',
  'WTU RETAIL ENERGY LP ',
  'XOOM ENERGY TEXAS LLC ',
  'YENTA ENERGY LP ',
  'YEP',
  'YOUNG ENERGY LLC ',
  'ZIP ENERGY'
];

function getUserSwitchDecision(contractEndDate) {
  const earliestSwitchDate = moment(contractEndDate).subtract(14, 'days');
  const daysOut = moment(earliestSwitchDate).diff(new Date(), 'days');
  if (daysOut < 43) {
    return 'canSwitch';
  }
  return 'noSwitch';
}

function findNewRate() {
  navigate('/?zipEntered=1');
}

function OfferList({ serviceAddress, usageAmount, lastBillAmount }) {
  const [offerList, setOfferList] = useState(null);
  const [bestAdvertisedPrice, setBestAdvertisedPrice] = useState(null);
  const [difference, setDifference] = useState(null);

  useEffect(() => {
    if (serviceAddress && serviceAddress.OfferList) {
      setOfferList(
        serviceAddress.OfferList.filter(
          (offer) => offer.ProductTypeID === 6
        ).map((offer) => {
          let _offer = offerCalc.getFullCostDetail(
            offer,
            parseInt(usageAmount),
            serviceAddress,
            null,
            'estimated',
            lastBillAmount ? parseFloat(lastBillAmount) : null
          );

          return _offer;
        })
      );
    }
  }, [serviceAddress]);

  useEffect(() => {
    if (offerList) {
      let _offerList = offerList.map((offer) => {
        return { ...offer };
      });

      let priceColumn = 'PriceKWh';
      _offerList.sort(
        (offerA, offerB) => offerA[priceColumn] - offerB[priceColumn]
      );

      const bestAdvertisedPriceList = _offerList.filter(
        (offer) => offer[priceColumn] === _offerList[0][priceColumn]
      );

      const _bestAdvertisedPrice =
        bestAdvertisedPriceList.length === 1
          ? { ...bestAdvertisedPriceList[0] }
          : {
              ...bestAdvertisedPriceList[
                Math.floor(Math.random() * bestAdvertisedPriceList.length)
              ]
            };

      setBestAdvertisedPrice({
        ..._bestAdvertisedPrice,
        label: 'Lowest Advertised Rate for ' + usageAmount + ' kWh',
        color: '#ed7d31'
      });
    }
  }, [offerList]);

  useEffect(() => {
    if (bestAdvertisedPrice) {
      setDifference(
        ((lastBillAmount - bestAdvertisedPrice.SingleMonthPriceDollars) /
          lastBillAmount) *
          100
      );
    }
  }, [bestAdvertisedPrice]);

  return bestAdvertisedPrice ? (
    <>
      {lastBillAmount && difference ? (
        offerCalc.round(difference, 0) == 0 ? (
          <p>
            Based on your last usage of {usageAmount} kWh, you would have paid
            about the same amount if you were on the current lowest advertised
            rate below.
          </p>
        ) : (
          <>
            <p>
              Based on your last usage of {usageAmount} kWh, you would have paid
              ${bestAdvertisedPrice.SingleMonthPriceDollars.toFixed(2)} if you
              were on the current lowest advertised rate below, which is about{' '}
              {Math.abs(difference).toFixed(0)}%{' '}
              {difference > 0 ? 'lower' : 'higher'} than your last bill amount
              of ${parseFloat(lastBillAmount).toFixed(2)}.
            </p>
            {difference > 0 ? (
              <p>
                <strong>
                  To decide if this savings is worth switching early, you'll
                  need to find out how much you would need to pay in Early
                  Termination Fees if you were to cancel your current contract.
                </strong>
              </p>
            ) : null}
          </>
        )
      ) : null}
      <ul className="featured-offer-list">
        <FeaturedOfferElectric
          offer={bestAdvertisedPrice}
          priceColumn={
            bestAdvertisedPrice.isTrueCost ? 'TrueCost_Dollars' : 'PriceKWh'
          }
          usage={usageAmount}
          usageSetting={'estimated'}
        />
      </ul>
      <p className="disclaimer">
        The price(s) shown above are based on an estimated usage of{' '}
        {usageAmount} kWh. The actual amount you pay will depend on how much
        electricity you actually use.
      </p>
    </>
  ) : (
    <LinearProgress />
  );
}

const steps = {
  start: {
    component: ({ updateOrderInfo, orderInfo }) =>
      orderInfo ? (
        <>
          <p>
            <strong>Are you moving or switching?</strong>
          </p>

          <FormControl component="fieldset" className="service-order-type">
            <RadioGroup
              aria-label="serviceOrderType"
              name="serviceOrderType"
              value={
                orderInfo && orderInfo[6] ? orderInfo[6].ServiceOrderType : ''
              }
              onChange={(event, newValue) => {
                updateOrderInfo('ServiceOrderType', newValue);
              }}>
              <FormControlLabel
                value="Move"
                className={
                  orderInfo &&
                  orderInfo[6] &&
                  orderInfo[6].ServiceOrderType === 'Move'
                    ? 'selected'
                    : ''
                }
                checked={
                  orderInfo &&
                  orderInfo[6] &&
                  orderInfo[6].ServiceOrderType === 'Move'
                }
                control={<Radio color="primary" />}
                label={
                  <>
                    <strong>Moving In</strong> I am starting new service in my
                    name
                  </>
                }
              />

              <FormControlLabel
                value="Switch"
                className={
                  orderInfo &&
                  orderInfo[6] &&
                  orderInfo[6].ServiceOrderType === 'Switch'
                    ? 'selected'
                    : ''
                }
                checked={
                  orderInfo &&
                  orderInfo[6] &&
                  orderInfo[6].ServiceOrderType === 'Switch'
                }
                control={<Radio color="primary" />}
                label={
                  <>
                    <strong>Switching</strong> I already have power in my name
                    and want to find a lower rate
                  </>
                }
              />
            </RadioGroup>
          </FormControl>
        </>
      ) : null,
    nextAction: (orderInfo, findStep) => {
      if (orderInfo && orderInfo[6]) {
        if (orderInfo[6].ServiceOrderType === 'Switch') {
          findStep('contractType');
        } else if (orderInfo[6].ServiceOrderType === 'Move') {
          findStep('move');
        }
      }
    },
    nextValidation: (orderInfo) => {
      if (orderInfo && orderInfo[6]) {
        if (orderInfo[6].ServiceOrderType) {
          return true;
        }
      }
      return false;
    }
  },
  move: {
    component: ({ updateOrderInfo, orderInfo, updateSwitchCalc }) => (
      <>
        <Alert>
          <strong>Good to go!</strong> You can{' '}
          <a className="link" onClick={() => findNewRate()}>
            set up service
          </a>{' '}
          to start on your move-in date 30-60 days ahead of time.
          <br />
          <br />
          If you already have service or are in a contract at your old location,
          you will not need to pay any early termination fees if you're moving
          to a new location. You'll just need to give your old provider your new
          forwarding address.
          <strong>
            <br />
            Now let's get your electric service scheduled. The process takes
            just a few minutes.
          </strong>
          <Button
            variant="contained"
            className="compare-button"
            color="secondary"
            onClick={() => findNewRate()}>
            Find lowest rate <ChevronRightIcon />{' '}
          </Button>
        </Alert>
      </>
    )
  },
  contractType: {
    component: ({ updateOrderInfo, orderInfo, updateSwitchCalc }) => (
      <>
        <p>
          <strong>Does your current electric plan have a contract?</strong>
        </p>

        <p>Your latest bill should show your contract terms.</p>
        <p>
          If you do not have your bill but you know when you signed up, try the
          EFL (Electricity Facts Label) for your current electric plan.
        </p>

        <FormControl component="fieldset" className="contract-selection">
          <RadioGroup
            aria-label="contractType"
            name="contractType"
            value={
              orderInfo && orderInfo[6] && orderInfo[6].switchCalc
                ? orderInfo[6].switchCalc.ContractOption
                : ''
            }
            onChange={(event, newValue) => {
              updateSwitchCalc('ContractOption', newValue);
            }}>
            {[
              'Yes, I have a contract',
              "No, I don't have a contract",
              'Not sure'
            ].map((option) => (
              <FormControlLabel
                value={option}
                className={
                  orderInfo &&
                  orderInfo[6] &&
                  orderInfo[6].switchCalc &&
                  orderInfo[6].switchCalc.ContractOption === option
                    ? 'selected'
                    : ''
                }
                checked={
                  orderInfo &&
                  orderInfo[6] &&
                  orderInfo[6].switchCalc &&
                  orderInfo[6].switchCalc.ContractOption === option
                }
                control={<Radio color="primary" />}
                label={<>{option}</>}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </>
    ),
    nextAction: (orderInfo, findStep) => {
      if (orderInfo && orderInfo[6] && orderInfo[6].switchCalc.ContractOption) {
        switch (orderInfo[6].switchCalc.ContractOption) {
          case 'Yes, I have a contract':
            findStep('contractLengthOption');
            break;
          case "No, I don't have a contract":
            findStep('canSwitch');
            break;
          case 'Not sure':
            findStep('moreInfo');
            break;
        }
      }
    },
    backAction: (orderInfo, findStep) => findStep('start'),
    nextValidation: (orderInfo) => {
      if (orderInfo && orderInfo[6] && orderInfo[6].switchCalc.ContractOption) {
        return true;
      }
      return false;
    }
  },
  contractLengthOption: {
    component: ({ updateOrderInfo, orderInfo, updateSwitchCalc }) => (
      <>
        <p>
          <strong>When does your current contract end?</strong>
        </p>

        <p>Your latest bill should show your contract terms.</p>
        <p>
          If you do not have your bill but you know when you signed up, try the
          EFL (Electricity Facts Label) for your current electric plan.
        </p>

        <FormControl component="fieldset" className="contract-selection">
          <RadioGroup
            aria-label="contractLengthOption"
            name="contractLengthOption"
            value={
              orderInfo && orderInfo[6] && orderInfo[6].switchCalc
                ? orderInfo[6].switchCalc.ContractLengthOption
                : ''
            }
            onChange={(event, newValue) => {
              updateSwitchCalc('ContractLengthOption', newValue);
            }}>
            {[
              'I know the contract end date',
              'I know my contract start date and the term length',
              'Not sure'
            ].map((option) => (
              <FormControlLabel
                value={option}
                className={
                  orderInfo &&
                  orderInfo[6] &&
                  orderInfo[6].switchCalc &&
                  orderInfo[6].switchCalc.ContractLengthOption === option
                    ? 'selected'
                    : ''
                }
                checked={
                  orderInfo &&
                  orderInfo[6] &&
                  orderInfo[6].switchCalc &&
                  orderInfo[6].switchCalc.ContractLengthOption === option
                }
                control={<Radio color="primary" />}
                label={<>{option}</>}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </>
    ),
    nextAction: (orderInfo, findStep) => {
      if (
        orderInfo &&
        orderInfo[6] &&
        orderInfo[6].switchCalc &&
        orderInfo[6].switchCalc.ContractLengthOption
      ) {
        if (orderInfo[6].switchCalc.ContractLengthOption === 'Not sure') {
          findStep('moreInfo');
        } else {
          findStep('contractDateOption');
        }
      }
    },
    backAction: (orderInfo, findStep) => findStep('contractType'),
    nextValidation: (orderInfo) => {
      if (
        orderInfo &&
        orderInfo[6] &&
        orderInfo[6].switchCalc.ContractLengthOption
      ) {
        return true;
      }
      return false;
    }
  },
  contractDateOption: {
    component: ({ updateOrderInfo, orderInfo, updateSwitchCalc }) => (
      <>
        <p>
          <strong>
            {orderInfo &&
            orderInfo[6] &&
            orderInfo[6].switchCalc &&
            orderInfo[6].switchCalc.ContractLengthOption ===
              'I know the contract end date'
              ? 'What is your contract end date?'
              : 'When did your contract begin?'}
          </strong>
        </p>

        <div className="date-picker">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <StaticDatePicker
              orientation="landscape"
              openTo="day"
              value={
                orderInfo &&
                orderInfo[6] &&
                orderInfo[6].switchCalc &&
                orderInfo[6].switchCalc.ContractDateSelection
                  ? orderInfo[6].switchCalc.ContractDateSelection
                  : null
              }
              disableHighlightToday={true}
              views={['day']}
              onChange={(date) => {
                updateSwitchCalc('ContractDateSelection', moment(date));
              }}
            />
          </LocalizationProvider>
        </div>
      </>
    ),
    backAction: (orderInfo, findStep) => findStep('contractLengthOption'),
    nextAction: (orderInfo, findStep) => {
      if (
        orderInfo &&
        orderInfo[6] &&
        orderInfo[6].switchCalc &&
        orderInfo[6].switchCalc.ContractDateSelection
      ) {
        if (
          orderInfo[6].switchCalc.ContractLengthOption ===
          'I know the contract end date'
        ) {
          findStep(
            getUserSwitchDecision(orderInfo[6].switchCalc.ContractDateSelection)
          );
        } else {
          findStep('contractTermLength');
        }
      }
    },
    nextValidation: (orderInfo) => {
      if (
        orderInfo &&
        orderInfo[6] &&
        orderInfo[6].switchCalc.ContractDateSelection
      ) {
        return true;
      }
      return false;
    }
  },
  contractTermLength: {
    component: ({ updateOrderInfo, orderInfo, updateSwitchCalc }) => (
      <>
        <p>
          <strong>What is the term length of your contract?</strong>
        </p>

        <p>
          Contract terms for electric plans typically range from 6 months to 3
          years. We need to know when your contract expires to help you decide
          if you can switch yet.
        </p>

        <div className="text-field">
          <TextField
            type="number"
            label="Contract length in months"
            placeholder="# of months"
            value={
              orderInfo && orderInfo[6] && orderInfo[6].switchCalc
                ? orderInfo[6].switchCalc.ContractLength
                : null
            }
            fullWidth
            onChange={(event) => {
              updateSwitchCalc('ContractLength', event.target.value);
            }}
          />
        </div>
      </>
    ),
    backAction: (orderInfo, findStep) => findStep('contractDateOption'),
    nextAction: (orderInfo, findStep) => {
      if (
        orderInfo &&
        orderInfo[6] &&
        orderInfo[6].switchCalc &&
        orderInfo[6].switchCalc.ContractDateSelection &&
        orderInfo[6].switchCalc.ContractLength
      ) {
        findStep(
          getUserSwitchDecision(
            moment(orderInfo[6].switchCalc.ContractDateSelection).add(
              orderInfo[6].switchCalc.ContractLength,
              'months'
            )
          )
        );
      }
    },
    nextValidation: (orderInfo) => {
      if (orderInfo && orderInfo[6] && orderInfo[6].switchCalc.ContractLength) {
        return true;
      }
      return false;
    }
  },
  provider: {
    component: ({ updateOrderInfo, orderInfo, updateSwitchCalc }) => (
      <>
        <p>
          <strong>Who is your current electric provider?</strong>
        </p>

        <p>
          Some electric providers charge early termination fees if you terminate
          your contract early.
        </p>

        <FormControl variant="outlined" className="select">
          <InputLabel id="current-provider-label">
            Select Your Provider
          </InputLabel>
          <Select
            fullWidth
            labelId="current-provider-label"
            label="Select Your Provider"
            id="current-provider-label"
            value={
              orderInfo && orderInfo[6] && orderInfo[6].switchCalc
                ? orderInfo[6].switchCalc.CurrentProvider
                : ''
            }
            onChange={(event) =>
              updateSwitchCalc('CurrentProvider', event.target.value)
            }>
            {providerList.map((providerName) => (
              <MenuItem value={providerName}>{providerName}</MenuItem>
            ))}
            <MenuItem value={'Other/Not Listed'}>Other/Not Listed</MenuItem>
          </Select>
        </FormControl>
      </>
    )
  },
  canSwitch: {
    component: ({
      updateOrderInfo,
      orderInfo,
      updateSwitchCalc,
      getContractEndDate,
      serviceAddress
    }) =>
      getContractEndDate ? (
        <>
          <Alert>
            <strong>Good to go!</strong> Based on the information you provided,
            it looks like you are safe to{' '}
            <a className="link" onClick={() => findNewRate()}>
              {moment(getContractEndDate(14, true)).isAfter(new Date())
                ? ' schedule your switch early.'
                : 'find a new electric plan.'}
            </a>
            <br />
            <br />
            {orderInfo &&
            orderInfo[6] &&
            orderInfo[6].switchCalc &&
            orderInfo[6].switchCalc.ContractOption ===
              "No, I don't have a contract" ? (
              <p>
                Since you're not in a contract with your current provider, you
                can{' '}
                <a className="link" onClick={() => findNewRate()}>
                  {' '}
                  take advantage of today's lower rates.
                </a>
              </p>
            ) : (
              <p>
                Your current contract expire
                {moment(getContractEndDate(0, true)).isAfter(new Date())
                  ? 's'
                  : 'd'}{' '}
                on {getContractEndDate()}.{' '}
                {moment(getContractEndDate(0, true)).isAfter(new Date()) ? (
                  <>
                    By Texas law, you can{' '}
                    <a className="link" onClick={() => findNewRate()}>
                      switch to a new plan
                    </a>{' '}
                    up to 14 days before this contract expires,
                    {moment(getContractEndDate(14)).isAfter(new Date()) ? (
                      <>
                        {' '}
                        which means{' '}
                        <strong style={{ display: 'inline' }}>
                          your earliest start date for your new plan{' '}
                          {moment(getContractEndDate(0, true)).isAfter(
                            new Date()
                          )
                            ? ' is '
                            : ' was '}{' '}
                          {getContractEndDate(14)}.
                        </strong>
                      </>
                    ) : (
                      <> and that date has passed!</>
                    )}
                  </>
                ) : null}
                <br />
                <br />
                <strong>
                  {moment(getContractEndDate(0, true)).isAfter(new Date())
                    ? moment(getContractEndDate(14)).isAfter(new Date())
                      ? "You can schedule your switch ahead of time and lock in today's lower rates. "
                      : " Schedule your switch now and lock in today's lower rates. "
                    : "Switch plans now and take advantage of today's lower rates. "}
                </strong>
              </p>
            )}
            It takes just a few minutes to find a new rate and sign up online.
            <Button
              variant="contained"
              className="compare-button"
              color="secondary"
              onClick={() => findNewRate()}>
              Find a new rate <ChevronRightIcon />{' '}
            </Button>
          </Alert>
        </>
      ) : null
    // backAction: (orderInfo, findStep) => {
    //   if (
    //     orderInfo &&
    //     orderInfo[6] &&
    //     orderInfo[6].switchCalc.ContractOption === "No, I don't have a contract"
    //   ) {
    //     findStep('contractType');
    //   } else {
    //     findStep('start');
    //   }
    // }
  },
  noSwitch: {
    component: ({
      updateOrderInfo,
      orderInfo,
      updateSwitchCalc,
      getContractEndDate
    }) => {
      const [showRemind, setShowRemind] = useState(null);

      function saveReminder() {
        if (showRemind && validateEmail(showRemind.Email)) {
          setShowRemind({ ...showRemind, Submitting: true });
          saveInquiry('SmarterChoice Switch Reminder', {
            Email: showRemind.Email,
            ...orderInfo[6].switchCalc
          }).then((result) =>
            setShowRemind({ ...showRemind, Submitted: true })
          );
        }
      }

      return getContractEndDate ? (
        <>
          <Alert severity="warning">
            {getContractEndDate() ? (
              <>
                <strong>Not quite yet.</strong>{' '}
                <p>
                  Based on the information you provided, it looks like you are
                  not able to switch electric plans yet.
                </p>
                <p>
                  Your current contract expires on {getContractEndDate()}. By
                  Texas law, you can switch to a new plan up to 14 days before
                  this contract expires, which means your earliest start date
                  for your new plan is {getContractEndDate(14)}.
                </p>
                <p>
                  <strong>
                    Most providers won't let you schedule a switch for more than
                    45 days out.
                  </strong>
                </p>
                {showRemind ? (
                  showRemind.Submitted ? (
                    <p>Got it! We'll email you when it's time to switch.</p>
                  ) : (
                    <TextField
                      label={'Enter your email'}
                      autoFocus
                      fullWidth
                      type="email"
                      value={showRemind.Email}
                      onChange={(event) =>
                        setShowRemind({
                          ...showRemind,
                          Email: event.target.value
                        })
                      }
                      onKeyDown={(event) => {
                        if (event.keyCode === 13) {
                          saveReminder();
                        }
                      }}
                      style={{ marginBottom: '-10px' }}
                    />
                  )
                ) : null}
                {!showRemind || !showRemind.Submitted ? (
                  <Button
                    variant="contained"
                    color="secondary"
                    className="compare-button"
                    disabled={
                      showRemind
                        ? !validateEmail(showRemind.Email) ||
                          showRemind.Submitting
                        : false
                    }
                    onClick={() => {
                      if (showRemind) {
                        saveReminder();
                      } else {
                        setShowRemind({ Email: '' });
                      }
                    }}>
                    {showRemind && showRemind.Submitting
                      ? 'Saving...'
                      : "Remind me when it's time"}
                  </Button>
                ) : null}
              </>
            ) : (
              <>
                <strong>More information needed.</strong>
                <p>
                  Before you can decide if you qualify for a new lower rate,
                  you'll need to look at your latest bill or the Electric Facts
                  Label for your current plan.
                </p>
                <p>
                  If you're in a contract that hasn't expired yet, you may have
                  to pay early termination fees if you switch too soon.
                </p>
              </>
            )}
          </Alert>
        </>
      ) : null;
    },
    nextButtonVariant: 'outlined',
    nextButtonLabel: 'Switch anyway?',
    nextAction: (orderInfo, findStep) => {
      findStep('earlySwitch');
    },
    backAction: (orderInfo, findStep) => {
      if (
        orderInfo &&
        orderInfo[6] &&
        orderInfo[6].switchCalc &&
        orderInfo[6].switchCalc.ContractOption === 'Not sure'
      ) {
        findStep('contractType');
      } else if (
        orderInfo &&
        orderInfo[6] &&
        orderInfo[6].switchCalc &&
        orderInfo[6].switchCalc.ContractLengthOption === 'Not sure'
      ) {
        findStep('contractLengthOption');
      } else {
        findStep('start');
      }
    }
  },

  earlySwitch: {
    component: ({
      updateOrderInfo,
      orderInfo,
      updateSwitchCalc,
      getContractEndDate
    }) => (
      <>
        <p>
          <strong>Wondering if it's worth it to switch early?</strong>
        </p>
        <p>
          Most providers charge Early Termination Fees if you switch to a
          different provider before your contract expires.
        </p>
        <p>
          In some cases, it may be worth it to switch to a lower rate early and
          pay those fees.
        </p>
        <p>
          If your current provider doesn't charge an Early Termination Fee, or
          if you can find a rate that is considerably lower than your current
          rate, you might consider this option.
        </p>
        <p>
          <strong>
            If you have a copy of your latest bill, we can help you calculate
            whether it is worth it to switch early.
          </strong>
        </p>
      </>
    ),
    nextAction: (orderInfo, findStep) => {
      findStep('latestBill');
    },
    backAction: (orderInfo, findStep) => {
      findStep('start');
    }
  },

  latestBill: {
    component: ({
      updateOrderInfo,
      orderInfo,
      updateSwitchCalc,
      getContractEndDate
    }) => (
      <>
        <p>
          <strong>
            To calculate potential savings if you switch to a new electric rate,
            you will need a copy of your latest bill.
          </strong>
        </p>
        <div className="text-field">
          <TextField
            type="number"
            label="Latest Bill Amount"
            placeholder="$"
            value={
              orderInfo && orderInfo[6] && orderInfo[6].switchCalc
                ? orderInfo[6].switchCalc.LastBillAmount
                : null
            }
            fullWidth
            onChange={(event) => {
              updateSwitchCalc('LastBillAmount', event.target.value);
            }}
          />
        </div>
        <div className="text-field">
          <TextField
            type="number"
            label="Latest Usage"
            placeholder="kWh"
            value={
              orderInfo && orderInfo[6] && orderInfo[6].switchCalc
                ? orderInfo[6].switchCalc.LastBillUsage
                : null
            }
            fullWidth
            onChange={(event) => {
              updateSwitchCalc('LastBillUsage', event.target.value);
            }}
          />
        </div>
      </>
    ),
    nextValidation: (orderInfo) => {
      if (
        orderInfo &&
        orderInfo[6] &&
        orderInfo[6].switchCalc &&
        orderInfo[6].switchCalc.LastBillAmount &&
        orderInfo[6].switchCalc.LastBillUsage
      ) {
        return true;
      }
      return false;
    },
    nextAction: (orderInfo, findStep) => {
      findStep('planResult');
    },
    backAction: (orderInfo, findStep) => {
      findStep('earlySwitch');
    }
  },

  planResult: {
    component: ({
      updateOrderInfo,
      orderInfo,
      siteConfig,
      serviceAddress,
      isActiveStep
    }) => (
      <>
        {isActiveStep ? (
          <OfferList
            serviceAddress={serviceAddress}
            usageAmount={orderInfo[6].switchCalc.LastBillUsage}
            lastBillAmount={orderInfo[6].switchCalc.LastBillAmount}
          />
        ) : null}
      </>
    ),
    minHeight: '700px',
    nextButtonLabel: 'View All Rates',
    nextButtonVariant: 'outlined',
    nextAction: (orderInfo, findStep) => {
      findNewRate();
    },
    backAction: (orderInfo, findStep) => {
      findStep('latestBill');
    }
  },

  moreInfo: {
    component: ({
      updateOrderInfo,
      orderInfo,
      updateSwitchCalc,
      getContractEndDate,
      findStep
    }) =>
      getContractEndDate ? (
        <>
          <Alert severity="warning">
            <strong>More information needed.</strong>
            <p>
              Before you can decide if you qualify for a new lower rate, you'll
              need to get a copy of your latest bill or the Electric Facts Label
              for your current plan.
            </p>
            <p>
              If you're in a contract that hasn't expired yet, you may have to
              pay early termination fees if you switch too soon.
            </p>
            <p>
              Once you have the information you need,{' '}
              <a className="link" onClick={() => findStep('contractType')}>
                click here to start over.
              </a>
            </p>
          </Alert>
        </>
      ) : null,
    backAction: (orderInfo, findStep) => {
      if (
        orderInfo &&
        orderInfo[6] &&
        orderInfo[6].switchCalc &&
        orderInfo[6].switchCalc.ContractOption === 'Not sure'
      ) {
        findStep('contractType');
      } else if (
        orderInfo &&
        orderInfo[6] &&
        orderInfo[6].switchCalc &&
        orderInfo[6].switchCalc.ContractLengthOption === 'Not sure'
      ) {
        findStep('contractLengthOption');
      } else {
        findStep('start');
      }
    }
  }
};

function SwitchCalculator({ siteConfig, serviceAddress }) {
  const requestedStep = useQueryParam('step', '');
  const [orderInfo, setOrderInfo] = useState({
    6: {
      switchCalc: {}
    }
  });
  const [activeStep, setActiveStep] = useState(0);
  const [hasZipCode, setHasZipCode] = useState(getCookie('mi-zip-code'));

  useEffect(() => {
    const cookieDetails = getCookie('mi-oi');
    if (cookieDetails) {
      try {
        let _orderInfo = JSON.parse(cookieDetails);
        if (
          _orderInfo[6] &&
          _orderInfo[6].switchCalc &&
          _orderInfo[6].switchCalc.ContractDateSelection
        ) {
          _orderInfo[6].switchCalc.ContractDateSelection = moment(
            _orderInfo[6].switchCalc.ContractDateSelection
          );
        }

        setOrderInfo({ ..._orderInfo });
      } catch (e) {}
    }

    setActiveStep(0);
  }, []);

  // useEffect(() => {
  //   if (window.innerWidth < 800) {
  //     const formTop = document.getElementsByClassName('steps')[0];

  //     if (formTop) {
  //       const yOffset = -80;

  //       const y =
  //         formTop.getBoundingClientRect().top + window.pageYOffset + yOffset;

  //       window.scrollTo({ top: y, behavior: 'smooth' });
  //     } else {
  //       window.scrollTo(0, 0);
  //     }
  //   }
  // }, [activeStep]);

  useEffect(() => {
    if (requestedStep) {
      let _stepIndex = -1;
      if (steps[requestedStep]) {
        Object.keys(steps).map((step, stepIndex) => {
          if (step === requestedStep) {
            _stepIndex = stepIndex;
          }
        });
      }

      if (_stepIndex !== -1) {
        setActiveStep(_stepIndex);
      }
    } else {
      setActiveStep(0);
    }
  }, [requestedStep]);

  useEffect(() => {
    if (orderInfo) {
      let _orderInfo = { ...orderInfo };
      if (
        _orderInfo[6] &&
        _orderInfo[6].switchCalc &&
        _orderInfo[6].switchCalc.ContractDateSelection
      ) {
        _orderInfo[6].switchCalc.ContractDateSelection = moment(
          _orderInfo[6].switchCalc.ContractDateSelection
        ).format('yyyy-MM-DD');
      }
      const orderInfoJson = JSON.stringify(_orderInfo);
      setCookie('mi-oi', orderInfoJson, 1);
    }
  }, [orderInfo]);

  useEffect(() => {
    if (activeStep > 0) {
      scrollToTop();
    }

    saveUserEvent(
      'UserAction',
      'SwitchCalculator',
      Object.keys(steps)[activeStep]
    );
  }, [activeStep]);

  useEffect(() => {
    if (
      serviceAddress &&
      serviceAddress.Address &&
      serviceAddress.Address.Zip &&
      getCookie('mi-zip-code')
    ) {
      setHasZipCode(true);
    }
  }, [serviceAddress]);

  function updateOrderInfo(field, value) {
    setOrderInfo({
      ...orderInfo,
      [6]: {
        ...orderInfo[6],
        [field]: value
      }
    });
  }

  function updateSwitchCalc(field, value) {
    updateOrderInfo(
      'switchCalc',
      orderInfo[6].switchCalc
        ? {
            ...orderInfo[6].switchCalc,
            [field]: value
          }
        : { [field]: value }
    );
  }

  function getStepClass(stepIndex) {
    if (stepIndex === activeStep) {
      return 'active';
    } else if (stepIndex > activeStep) {
      return 'right';
    }
    return '';
  }

  function getContractEndDate(daysToSubtract, returnDate) {
    const contractDateSelection =
      orderInfo[6].switchCalc && orderInfo[6].switchCalc.ContractDateSelection
        ? orderInfo[6].switchCalc.ContractDateSelection
        : null;

    if (contractDateSelection) {
      let contractEndDate = null;
      if (
        orderInfo[6].switchCalc.ContractLengthOption ===
        'I know the contract end date'
      ) {
        contractEndDate = moment(contractDateSelection);
      } else if (
        orderInfo[6].switchCalc.ContractLengthOption ===
          'I know my contract start date and the term length' &&
        orderInfo[6].switchCalc.ContractLength
      ) {
        contractEndDate = moment(contractDateSelection).add(
          orderInfo[6].switchCalc.ContractLength,
          'months'
        );
      }

      if (contractEndDate) {
        const dateValue = contractEndDate.subtract(
          daysToSubtract ? daysToSubtract : 0,
          'days'
        );
        if (returnDate) {
          return dateValue;
        }
        return moment(dateValue).format('M/D/yyyy');
      }
    }
    return '';
  }

  function findStep(stepKey) {
    let _stepIndex = -1;
    if (steps[stepKey]) {
      Object.keys(steps).map((step, stepIndex) => {
        if (step === stepKey) {
          _stepIndex = stepIndex;
        }
      });
    }

    if (_stepIndex !== -1) {
      setActiveStep(_stepIndex);
      setRoute(stepKey);
    }

    return _stepIndex;
  }

  function setRoute(stepKey) {
    window.history.pushState({}, '', '/switch/?step=' + stepKey);

    //navigate('/switch/?step=' + stepKey, { state: { noScroll: true } });
  }

  function scrollToTop() {
    const formTop = document.getElementsByClassName('steps')[0];

    if (formTop) {
      const yOffset = -80;

      const y =
        formTop.getBoundingClientRect().top + window.pageYOffset + yOffset;

      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  }

  return (
    <div className="article-container switch">
      <h1>
        <div>
          Do you qualify for a lower electric rate? We'll help you decide.
        </div>
      </h1>

      <div className="page-container text-container switch-calculator">
        <div className="page">
          <div className="inner">
            <div
              className={'steps'}
              style={
                steps[Object.keys(steps)[activeStep]].minHeight
                  ? {
                      minHeight: steps[Object.keys(steps)[activeStep]].minHeight
                    }
                  : {}
              }>
              {hasZipCode && serviceAddress ? (
                serviceAddress.OfferList &&
                serviceAddress.OfferList.filter(
                  (offer) => offer.ProductTypeID === 6
                ).length ? (
                  Object.keys(steps).map((step, stepIndex) => {
                    const StepComponent = steps[step].component;
                    return (
                      <>
                        <div
                          key={step}
                          className={'step ' + getStepClass(stepIndex)}>
                          <StepComponent
                            updateOrderInfo={updateOrderInfo}
                            orderInfo={orderInfo}
                            updateSwitchCalc={updateSwitchCalc}
                            getContractEndDate={getContractEndDate}
                            findStep={findStep}
                            serviceAddress={serviceAddress}
                            siteConfig={siteConfig}
                            isActiveStep={stepIndex === activeStep}
                          />

                          {steps[step].backAction ? (
                            <Button
                              className="nav-button left"
                              onClick={() =>
                                steps[step].backAction(orderInfo, findStep)
                              }>
                              <ChevronLeftIcon /> Back
                            </Button>
                          ) : null}

                          {!steps[step].hideNext && steps[step].nextAction ? (
                            <Button
                              variant={
                                steps[step].nextButtonVariant
                                  ? steps[step].nextButtonVariant
                                  : 'contained'
                              }
                              color="secondary"
                              onClick={() =>
                                steps[step].nextAction(orderInfo, findStep)
                              }
                              disabled={
                                steps[step].nextValidation &&
                                !steps[step].nextValidation(orderInfo)
                              }
                              className="nav-button next">
                              {steps[step].nextButtonLabel
                                ? steps[step].nextButtonLabel
                                : 'Next'}{' '}
                              <ChevronRightIcon />
                            </Button>
                          ) : null}
                        </div>
                      </>
                    );
                  })
                ) : (
                  <NoDeregulatedElectric includeAddressSearch />
                )
              ) : (
                <div className="step active">
                  <p className="highlight">
                    <strong>First off, what is your zip code?</strong>
                  </p>
                  <p>Electric rates are based on your location.</p>
                  <AddressSearch
                    hideSearchBy
                    mode="zip"
                    searchButtonLabel={'Submit'}
                    addressLabel={'Enter your zip code here'}
                    autoFocus={true}
                    afterAddressSelected={() => {
                      //do nothing - stay here
                      scrollToTop();
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    siteConfig: state.commonData.siteConfig,
    serviceAddress: state.serviceAddress
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      pageLoading: bindActionCreators(webSocketActions.pageLoading, dispatch)
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SwitchCalculator);
